import async from "../components/Async";
import { API, ROTAS, sessionManager } from "../services/api";

import { faChartPie, faHome, faCog, faSignInAlt, faTasks, faBarcode, faAdjust, faSearch, faFileMedical } from "@fortawesome/free-solid-svg-icons";

// Auth
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Default
const Default = async(() => import("../pages/home/Home"));
const Home = async(() => import("../pages/home/Home"));
const ASO = async(() => import("../pages/qls/aso"));
const IndexUsuario = async(() => import("../pages/admin/IndexUsuario"));

// add here all the components imported
const componentMap = {
	Default,
	Home,
	ASO,
	IndexUsuario,
};


// add here all the icons imported
const iconMap = {
	faChartPie,
	faHome,
	faCog,
	faSignInAlt,
	faTasks,
	faBarcode,
	faAdjust,
	faSearch,
	faFileMedical,
};

// normalize lists from DB
const keyMapping = {
	ativo: "auth",
	componente: "component",
	icone: "icon",
	nome: "name",
	ordem: "order",
	pai_id: "parentId",
	permissao: "permission",
	rota: "path",
};

// use mapping to translate each entry on the API response
const translateKeys = (route) => {
	const translatedRoute = {};
	Object.keys(route).forEach(key => {
		const translatedKey = keyMapping[key] || key;
		let value = route[key];
		if (translatedKey === "component") {
			value = componentMap[value] || null;
		} else if (translatedKey === "icon") {
			value = iconMap[value];
		}
		translatedRoute[translatedKey] = value;
	});
	return translatedRoute;
};

// get the routes from DB and export as function
export const getRoutes = async () => {
	try {
		const resposta = await API.get(ROTAS.OBTER_ROTAS_DO_BANCO);
		console.log("resposta:", resposta);
		const routesFromDB = resposta.data.retorno;

		// Process and translate routes from DB
		let processedRoutesFromDB = routesFromDB.map(route => {
			const translatedRoute = translateKeys(route);
			return translatedRoute;
		});

		// Sort routes by order
		processedRoutesFromDB.sort((a, b) => a.order - b.order);

		let parentRoutes = processedRoutesFromDB.filter((route) => {
			return route.parentId == null;
		});
		let routesToExport = [];
		parentRoutes.forEach((parentRoute) => {
			let childRoutes = processedRoutesFromDB.filter((route) => {
				if (!route.parentId) return;
				return parentRoute.id == route.parentId;
			});

			// preenche modifiedChildRoutes com objetos no formato do export
			let modifiedChildRoutes = [];

			// 1. trocar a propriedade `.parentId` por `.children` e setá-la `null`
			childRoutes.forEach((childRoute) => {
				modifiedChildRoutes.push(Object.assign({}, childRoute, { children: null }));
			});

			// caso este parent estiver com .children vazio, seta para `null`
			let thisParentChildren = modifiedChildRoutes.length > 0 ? modifiedChildRoutes : null;

			// gerar os routeParents com children no formato do export:
			// trocar a propriedade `.parentId` por `.children` e setá-la array `thisParentChildren`
			routesToExport.push(Object.assign({}, parentRoute, { children: thisParentChildren }));
		});

		const setAndReturnRouteAuth = (route) => {
			// will set and return the `route.auth` with the possible values:
			// 1 (visible) and 0 (not visible)
			
			if(route.path == "/oculto") return route.auth = 0;

			// if `route.permission` is null, this means that there is no requirement to not show the route
			return route.auth = 1;
		}
	
		const setAuths = () => {
			// `route.auth = 1` means that the route is available for that user
			
			// if routes[index] is parent: check auth of "children routes", add it to an array `childArray`
			// if childArray.length > 0, set parent.auth to 1
	
			routesToExport.forEach((route)=>{
				// filtro para não modificar as rotas funcionais
				if( route.path == "/login" || route.path == "/header" || route.path == "/auth" || route.permission == "oculto" ){
					route.auth = 0;
				} else {
					// simple validation in case .children becomes `[]` instead of `null` in routes/index.js
					let childrenArray = route.children || null;
	
					// check if not `parent` of any child route (e.g. `Meu Pot`):
					if(childrenArray == null){
						setAndReturnRouteAuth(route);
					}
					// else it is `parent` of a route
					else {
						let authsArray = childrenArray.map(child => {
							return setAndReturnRouteAuth(child);
						});
	
						// if there is any single visible child for this parent, sets .auth = 1, else 0
						if(authsArray.length > 0){
							setAndReturnRouteAuth(route);
						}
					}
				}
			});
			
		}
		setAuths();
		sessionManager.routes.set(routesToExport);
		return routesToExport;
	} catch (error) {
		console.error("Error fetching routes:", error);
		return [];
	}
};

// VERIFICAR REAL IMPORTANCIA DISTO!
// DUPLICATA DE DADOS..

// LOGIN, 404 E 500 SAO IMPORTADAS DIRETAMENTE EM ROUTES.JS VEEM TAMBEM DO BANCO E TAMBEM SAO ADICIONADAS EM DUAS OUTRAS VARIAVEIS AQUI.
// 3X 

export const page = [
{
	path: "/auth",
	name: "Auth",
	icon: faSignInAlt,
	children: [
	{
		path: "/auth/404",
		name: "404 Page",
		component: Page404
	},
	{
		path: "/auth/500",
		name: "500 Page",
		component: Page500
	}
	]
}
];

// Exibição menu
export default getRoutes;

import React, { useState } from "react";
import axios from "axios";
import ModalConfirmacao from "../modals/ModalConfirmacao";
import history from "../routes/history";

const API_DOMINIO = process.env.REACT_APP_API_DOMINIO;
const API_PORTA = process.env.REACT_APP_API_PORTA;
const API_PROTOCOLO = process.env.REACT_APP_API_PROTOCOLO;

export const ROTAS = {
	OBTER_ROTAS_DO_BANCO: "obter_rotas",

	USUARIO_LOGIN: "login",
	USUARIOS_OBTER: "obter_usuarios",
	USUARIO_CADASTRAR: "usuario_incluir",
	USUARIO_ALTERAR: "usuario_alterar",

	FUNCIONARIOS_OBTER: "obter_funcionarios",
	FUNCIONARIOS_ATUALIZAR_SOC: "atualizar_soc_funcionario",

	KPIS_OBTER: "obter_kpis",
	ASO_KPIS_OBTER: "obter_aso_kpis",
    ASO_VALIDAR: "aso_validar",
};

const createSessionManager = ({ key, isLocalStorage = false, isObject = false }) => {
	const storage = isLocalStorage ? localStorage : sessionStorage;
	return {
		set: (value) => {
			const stringValue = isObject ? JSON.stringify(value) : value;
			return storage.setItem(key, stringValue)
		},
		get: () => {
			const value = storage.getItem(key);
			return isObject ? JSON.parse(value) : value;
		},
		remove: () => storage.removeItem(key),
	};
}

export const sessionManager = {
	dadosUsuarioLogado: createSessionManager({
		key: "dados-usuario-logado",
		isLocalStorage: true,
		isObject: true,
	}),
	openRoutes: createSessionManager({
		key: "open-routes",
		isLocalStorage: false,
		isObject: true,
	}),
    routes: createSessionManager({
		key: "routes",
		isLocalStorage: false,
		isObject: true,
	}),
    scrollPosition: createSessionManager({
		key: "scroll-position",
		isLocalStorage: false,
		isObject: false,
	}),
    ultimoUrlCaminho: createSessionManager({
		key: "ultimo-url-caminho",
		isLocalStorage: true,
		isObject: false,
	}),
	
	pathRoute: createSessionManager({
		key: "path-route",
		isLocalStorage: true,
		isObject: false,
	}),
    appUser: createSessionManager({
		key: "app-user",
		isLocalStorage: true,
		isObject: true,
	}),
    appDepartamento: createSessionManager({
		key: "app-departamento",
		isLocalStorage: true,
		isObject: true,
	}),
    appPermissoes: createSessionManager({
		key: "app-permissoes",
		isLocalStorage: true,
		isObject: true,
	}),
    appDatapush: createSessionManager({
		key: "app-datapush",
		isLocalStorage: true,
		isObject: true,
	}),
}

export const logoutDoSistema = (cancelarRedirecionamento = false) => {
	// remover todas as variáveis da sessão não necessárias do browser
	sessionManager.routes.remove();
	sessionManager.openRoutes.remove();
	sessionManager.dadosUsuarioLogado.remove();

	// configura o ultimo caminho ao sair
	sessionManager.ultimoUrlCaminho.set(history.location.pathname + history.location.search);

	// redirecionar para o login caso não explicito para cancelar redirecionamento 
	if (cancelarRedirecionamento === false) {
		history.push("/login");
	}
}

const headers = {
    "Content-Type": "application/json",
    "Authorization": "Bearer " + process.env.REACT_APP_API_TOKEN,
    "token": sessionManager.dadosUsuarioLogado.get()?.token || "",
};

// const hostname = window.location.hostname;
// const dominio_hostname = API_PROTOCOLO + API_DOMINIO : API_PROTOCOLO + hostname;
const dominio_hostname = API_PROTOCOLO + API_DOMINIO;

export const API = axios.create({
	baseURL: dominio_hostname + ":" + API_PORTA,
	headers: headers,
});

export const InterceptadorApi = () => {
    const [mostrar, setMostrar] = useState(false);

    // interceptará a resposta e verificará se o erro é 3003,
    // sendo assim, desloga usuário quando o mesmo clicar o botão
    API.interceptors.response.use(
        (response) => response,
        (error) => {
            if (window.location.pathname !== "/login" && error?.response?.data?.erro_codigo === 3003) {
				logoutDoSistema(true);
                setMostrar(true);
            }
            return Promise.reject(error);
        }
    );

    return (
        <ModalConfirmacao
            textoTitulo={"Sessão expirada"}
            textoDescritivo={"Por favor, faça login novamente"}
            mostrar={mostrar}
            setMostrar={() => setMostrar(false)}
            callbackSeSim={logoutDoSistema}
            forcarUsuario={true}
        />
    );
};

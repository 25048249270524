import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { sessionManager } from '../services/api';

const RotaPrivada = (props) => {
    const token = sessionManager.dadosUsuarioLogado.get();
    const temToken = !! token;

    // verifica se tem permissão para acessar a rota
    
    let jsx = null;

    // TODO: fazer a validação se a rota acessada está no `routes` do usuário
    // se estiver renderiza rota
    // se não, mostrar 403

    if(temToken === true) {
        jsx = <Route {... props}/>;
    }
    else {
        jsx = <Redirect to="/login" />;
    }
    
    return jsx;
    
}

export default RotaPrivada;